@media print {
  body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .print-w {
    display: flex;
  }
  .image{
    margin: 10px;
  }
  body * {
    visibility: hidden;
  }
  #section-print0, #section-print0 *, #section-print1, #section-print1 *, #section-print2, #section-print2 *, #section-print3, #section-print3 * {
    visibility: visible;
  }
  #section-print0 {
    position: absolute;
    left: 0;
    top: 10%;
    right: 0;
    bottom: 0;
  }
  #section-print1 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #section-print2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #section-print3 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@page {
  size: A4;
  margin: 1cm;
}
