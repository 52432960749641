@media print {
  body {
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente */
    margin-bottom: 0;
  }
  .image{
    margin: 10px;
  }
  body * {
    visibility: hidden;
  }
  #section-print, #section-print * {
    visibility: visible;
  }
  #section-print {
    position: absolute;
    left: 0;
    top: 10%;
    right: 0; /* Centra horizontalmente */
    bottom: 0; /* Centra verticalmente */
  }
  #section-print .print-w {
    display: none;
  }

}

@page {
  size: A4;
  margin: 1cm;
}
